






































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { authorizationService } from "@/shared/services/AuthorizationService";
import { UserProfileItem } from "@/components/header/UserProfile/UserProfileItem";
import { UtilsString } from "@/utils/utils-string";
import ConfiguracionModule from "@/store/modules/Configuracion-module";
import { JwtService } from "@/shared/services/JwtService";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {},
})
export default class UserProfile extends Vue {
  public items: UserProfileItem[] = [
    {
      icon: "fad fa-sign-out-alt miprimary fa-lg ma-2",
      text: "",
      i18n: "Cerrar sesión",
      id: 0,
    },
    {
      icon: "fad fa-user-circle miprimary fa-lg ma-2",
      text: "",
      i18n: "Ver perfil",
      id: 1,
    },
  ];
  public menu: boolean = false;

  public created() {
    if (JwtService.jwtDecode().nameid !== undefined) {
      ConfiguracionModule.getConfiguracionForBack(
        JwtService.jwtDecode().nameid
      ).then((e) => {
        ConfiguracionModule.GetImageNutricionista(
          ConfiguracionModule.ConfiguracionForBack
        );
      });
    }
  }
  public get userName() {
    return authorizationService.userName();
  }
  public get getImagen() {
    return ConfiguracionModule.urlImage;
  }

  public logout() {
    authorizationService.logout();
    this.$router.push("/login");
  }

  public opcion(item: UserProfileItem) {
    if (item.id === 0) {
      this.logout();
    } else if (item.id === 1) {
      this.$router.push({ name: RouterNames.configuracion_ficha });
    }
    this.menu = false;
  }
}
